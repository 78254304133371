<template>
    <div class="warp" style="background:#000;" v-loading="Loading" element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="main">
            <div>
                <p style="padding:6px 6px;float:right;"><span>当前角色：{{ info.charac_name }} <span style="color:#797979;"
                            @click="LoginDisplay = 'display:block'">《登陆|换绑》</span></span></p>
            </div>
            <div class="top" style="position:absolute;left:226px;top:94px;color:#516c96;font-weight:600;">
                <p style="margin-left:26px;">{{ info.on_off }}</p>
            </div>
            <div class="tab tab-mid" style="position:absolute;left:190px;top:98px;">
                <div class="tab-item checkpoint1 active"></div>
                <div class="tab-item checkpoint2"></div>
            </div>
            <div class="quest-page">
                <div class="quest-page-item active">
                    <ul class="task">
                        <template v-for="(v, i) in info.plug_wit_task">
                            <li v-if="v.type == 1" :key="i">
                                <div class="ioc">
                                    <img src="../../../public/static/plug/wit/static/images/15.png">
                                </div>
                                <span class="ioc_num">{{ v.exp }}</span>
                                <div class="content">
                                    <p class="title">{{ v.name }}
                                        <el-tooltip class="item" effect="dark" content="" placement="top-start">
                                            <div slot="content">
                                                <p v-for="(de, c) in v.details" :key="c" style="color:rgb(169 255 0)">{{
                                                        de
                                                }}</p>
                                            </div>
                                            <span style="color:#1b1b1b;" class="el-icon-question"></span>
                                        </el-tooltip>
                                    </p>

                                    <div class="progress">
                                        <div class="progress-bar"><span class="progress-current"
                                                :style="v.percentage"></span></div>
                                        <span v-if="v.way == 1" class="num">（{{ v.wc_num }}/{{ v.room_num }}）</span>
                                        <span v-else-if="v.way == 2" class="num">（{{ v.wc_num }}/{{ v.items_num
                                        }}）</span>
                                        <span v-else class="num">（0/0）</span>
                                    </div>
                                </div>
                                <div v-if="v.complete == 1" class="ico_button_lq" @click="taskReceive(v.id)"></div>
                                <div v-else-if="v.complete == 2" class="ico_button_wq"></div>
                                <div v-else class="ico_button"></div>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="quest-page-item">
                    <ul class="task">
                        <template v-for="(v, i) in info.plug_wit_task">
                            <li v-if="v.type == 2" :key="i">
                                <div class="ioc">
                                    <img src="../../../public/static/plug/wit/static/images/15.png">
                                </div>
                                <span class="ioc_num">{{ v.exp }}</span>
                                <div class="content">
                                    <p class="title">{{ v.name }}
                                        <el-tooltip class="item" effect="dark" content="" placement="top-start">
                                            <div slot="content">
                                                <p v-for="(de, c) in v.details" :key="c" style="color:rgb(169 255 0)">{{
                                                        de
                                                }}</p>
                                            </div>
                                            <span style="color:#1b1b1b;" class="el-icon-question"></span>
                                        </el-tooltip>
                                    </p>
                                    <div class="progress">
                                        <div class="progress-bar"><span class="progress-current"
                                                :style="v.percentage"></span></div>
                                        <span v-if="v.way == 1" class="num">（{{ v.wc_num }}/{{ v.room_num }}）</span>
                                        <span v-else-if="v.way == 2" class="num">（{{ v.wc_num }}/{{ v.items_num
                                        }}）</span>
                                        <span v-else class="num">（0/0）</span>
                                    </div>
                                </div>
                                <div v-if="v.complete == 1" class="ico_button_lq" @click="taskReceive(v.id)"></div>
                                <div v-else-if="v.complete == 2" class="ico_button_wq"></div>
                                <div v-else class="ico_button"></div>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <div class="first">
                <div class="first_left">
                    <div class="first_level">Lv.{{ info.config.level }}</div>
                    <div class="first_num">{{ info.config.wc_exp }}/{{ info.config.exp }}</div>
                </div>
                <div class="first_right">
                    <ul class="items_level">
                        <li v-for="(v, i) in info.plug_wit_items" :key="i">
                            <div>
                                <img src="../../../public/static/plug/wit/static/images/33.png">
                                <img class="lj" v-on:mouseover="lj_info($event, v.code)"
                                    v-on:mouseout="removeActive($event)" :src="v.ioc"
                                    style="position:absolute;left:26px;top:4px;width:28px;height:28px;">
                            </div>
                            <p v-if="v.receive == 1" class="level_lq" @click="receive(v.id)"></p>
                            <p v-else-if="v.receive == 2" class="level_ylq"></p>
                            <p v-else class="level_dlq">Lv.{{ v.level }}</p>
                        </li>
                    </ul>
                </div>
                <span class="but_left"></span>
                <span class="but_right"></span>
            </div>
        </div>
        <div class="iteminfo">
            <div style="text-align:center;">物品礼包</div>
            <div v-for="(itmec, i) in itme_show_data" :key="i" style="padding:0px 6px;">{{ itmec.name }}x{{ itmec.num }}
            </div>
        </div>
        <div class="alert">
            <!-- 登陆框 -->
            <div class="alert_bg" :style="LoginDisplay">
                <div class="alert_main" style="margin-top:86px;width:350px;">
                    <div class="alert_title"><span>绑定|切换</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <p style="margin-bottom:6px;display:flex;"><span
                                    style="padding:0px 0px 0px 32px;">游戏账号：</span><input type="text"
                                    v-model="login_info.username" class="service__input"
                                    style="background:#000;border:1px solid #442e21;color:#abce21;width: 220px;"></p>
                            <p style="margin-bottom:6px;display:flex;"><span
                                    style="padding:0px 0px 0px 32px;">游戏密码：</span><input type="password"
                                    v-model="login_info.password" class="service__input"
                                    style="background:#000;border:1px solid #442e21;color:#abce21;width: 220px;"></p>
                            <p style="margin-bottom:6px;display:flex;"><span
                                    style="padding:0px 0px 0px 32px;">绑定角色：</span>
                                <select v-model="login_info.role"
                                    style="width:173px;background:#000;border:1px solid #442e21;color:#abce21;">
                                    <option v-for="(itmec, i) in role_data" :key="i" :value="itmec.charac_no">
                                        {{ itmec.charac_name }}
                                    </option>
                                </select>
                                <span
                                    style="background:#7478e4;padding:3px 10px;margin-left:2px;border-radius:4%;cursor:pointer;"
                                    @click="getRole">获取</span>
                            </p>
                            <div>
                                <span style="padding:0px 0px;color:#f90000;"><span
                                        style="display:inline-block;padding:0px 0px;">{{
                                                LoginError
                                        }}</span></span>
                            </div>
                            <div style="text-align:center;padding:10px 0px">
                                <div class="btn" @click="login"
                                    style="margin:0px 0px;display:inline-block;cursor:pointer;">确定
                                </div>
                                <div class="btn" @click="LoginDisplay = 'display:none'"
                                    style="margin:0px 0px;display:inline-block;cursor:pointer;">取消</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <!-- 登陆框 end-->
            <!-- 弹出框 -->
            <div class="alert_bg" :style="MessageDisplay">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>
                            <div style="text-align:center;padding:10px 0px">
                                <div class="btn" @click="CloseAlert" style="margin:0px 0px;display:inline-block;">
                                    关闭</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <div class="alert_bg" :style="SuccessDisplay">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>

                            <div style="text-align:center;padding:10px 0px">
                                <div class="btn" @click="SuccessAlert" style="margin:0px 0px;display:inline-block;">关闭
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import Vue from 'vue'
export default {
    name: "Wit",
    data () {
        return {
            MessageDisplay: "display:none", //弹出框显示
            SuccessDisplay: 'display:none',//成功弹出框
            LoginDisplay: "display:none", //登陆弹出框
            LoginError: '',
            MessageMsg: '', //弹出框消息
            Loading: false,
            info: {
                m_id: 0, //账号uid
                charac_no: 0, //角色ID
                charac_name: '', //角色名称
                on_off:'', //活动时间
                plug_wit_task: [],
                plug_wit_items: [],
                config: {
                    wc_exp: 0,
                    exp: 0,
                    level: 0,
                },
            },
            login_info: {
                username: '',
                password: '',
                role: '',
            },
            itme_show_data: [], //展示物品用
            role_data: [],
        };
    },
    created () {
        this.getInfo()
    },
    methods: {
        //获取基本配置信息
        getInfo () {
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/wit/getWinInfo",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.info = response.data.info
                    } else {
                        this.PopMessage(true, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //登陆
        login () {
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/PlugLogin",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    username: this.login_info.username,
                    password: this.login_info.password,
                    role: this.login_info.role,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.LoginDisplay = "display:none";
                        this.getInfo();
                    } else {
                        this.LoginError = response.data.msg;
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //获取角色
        getRole () {
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/getRole",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    username: this.login_info.username,
                    password: this.login_info.password,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.role_data = response.data.info;
                    } else {
                        this.LoginError = response.data.msg;
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        taskReceive (id) {
            if (this.Loading == true) {
                return false
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/wit/witTaskReceive",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    id: id,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.PopMessage(true, response.data.msg)
                    } else {
                        this.click = false;
                        this.PopMessage(false, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        receive (id) {
            if (this.Loading == true) {
                return false
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/wit/witReceive",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    id: id,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.PopMessage(true, response.data.msg)
                    } else {
                        this.click = false;
                        this.PopMessage(false, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //弹出框状态
        PopMessage (type, msg) {
            this.SuccessDisplay = "display:none";
            this.MessageDisplay = "display:none";
            this.MessageMsg = msg;
            if (type == false) {
                this.MessageDisplay = "display:block";
            } else {
                this.SuccessDisplay = "display:block";
            }
        },

        //关闭窗口
        CloseAlert () {
            this.SuccessDisplay = "display:none";
            this.MessageDisplay = "display:none";
        },
        SuccessAlert () {
            this.SuccessDisplay = "display:none";
            this.MessageDisplay = "display:none";
            this.getInfo();
        },
        lj_info ($event, itmes) {
            this.itme_show_data = itmes
            var container = document.getElementsByClassName("iteminfo");
            if (typeof (container) != "undefined") {
                container[0]
                var x = $event.x
                var y = $event.y
                console.log("11")
                if (x > 1167) {
                    container[0].style.left = (x - 77) + 'px'
                } else {
                    container[0].style.left = (x - 73) + 'px'
                }
                container[0].style.top = y + 'px'
                container[0].style.display = 'block'
                let item_h = container[0].offsetHeight + container[0].style.top;
                if (item_h > 435) {
                    y -= (item_h - 435);
                    container[0].style.top = y + 'px'
                }
            }
        },
        removeActive ($event) {
            console.log($event)
            var container = document.getElementsByClassName("iteminfo");
            if (typeof (container) != "undefined") {
                container[0].style.display = 'none'
            }
        },
    },
};
$(function () {
    $('.tab-item').click(function () {
        var index = $(this).index();
        $('.quest-page-item').removeClass('active');
        $('.tab-item').removeClass('active');
        $(this).addClass('active');
        $('.quest-page-item').eq(index).addClass('active');
    });

    var _index5 = 0;
    var ckec = false;
    $(".but_right").click(function () {
        var len = $(".first_right ul.items_level li").length;
        var count = len / 5;
        if (ckec == true) {
            return;
        }
        if (_index5 + 1 >= count) {
            return;
        }
        _index5++;
        console.log(_index5);
        console.log(count);
        $(".first_right ul.items_level").stop().animate({ left: -_index5 * 418 }, 100);

        ckec = false;
    });
    $(".but_left").click(function () {
        if (_index5 == 0) {
            return;
        }
        if (ckec == true) {
            return;
        }
        _index5--;
        $(".first_right ul.items_level").stop().animate({ left: -_index5 * 418 }, 100);
        ckec = false;
    });
});
</script>

<style scoped>
@import "../../../public/static/plug/wit/static/css/game.css";
</style>

<style>
    #root,
    body,
    html {
        min-width: 0px;
    }
    </style>
